import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

import './index.scss';

const TitleBanner = () => (
  <div id="title-banner">
    <h1 style={{ display: 'none' }}>Title Page</h1>

    <p style={{ color: '#34495e', textShadow: 'none' }}>N. Freeman</p>

    <h1 style={{ fontSize: '6vw' }}>
      <em style={{ fontSize: '3vw' }}>The Fundamentals of</em>
      <br />
      Web Application Development
    </h1>

    <h2 style={{ color: '#ffcc00', textShadow: '1pt 1pt grey' }}>
      Web Edition
    </h2>

    <strong
      style={{
        color: 'white',
        textShadow: '1pt 1pt darkred, -1pt -1pt red',
        letterSpacing: '1pt',
      }}
    >
      DRAFT
    </strong>
  </div>
);

const TocItem = ({ node }) => (
  <li key={node.fields.slug}>
    <h3>
      <Link
        style={{ boxShadow: `none` }}
        to={node.frontmatter.slug}
        dangerouslySetInnerHTML={{ __html: node.frontmatter.title }}
      />
    </h3>
    {!node.frontmatter.hideToc && (
      <ul>
        {node.headings.map(h => (
          <li key={h.value} dangerouslySetInnerHTML={{ __html: h.value }} />
        ))}
      </ul>
    )}
  </li>
);

const Part = ({ children }) => (
  <li>
    <h2 className="part-title">{children}</h2>
  </li>
);
const part = num => ({ node }) => node.frontmatter.part == num;

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        next={{ frontmatter: { slug: '/preface', title: 'Preface' } }}
      >
        <SEO
          keywords={[
            'javascript',
            'css',
            'html',
            'web apps',
            'web development',
          ]}
          title={siteTitle}
        />
        <TitleBanner />
        <section>
          <p>
            <strong>
              <em>Welcome!</em>
            </strong>{' '}
            Please enjoy this work-in-progress.
          </p>
          <p>
            <strong>How to read this book:</strong>
          </p>
          <ul>
            <li>
              Use the navigation on the bottom of the screen to read through the
              chapters in order.
            </li>
            <li>
              Jump to a specific chapter using the links in the table of
              contents below.
            </li>
          </ul>
        </section>
        <section className="toc-container">
          <h1 id="contents" style={{ color: '#34495e' }}>
            Contents
          </h1>
          <ul className="index-toc">
            {posts.filter(part(0)).map(TocItem)}

            <Part>Part I - Introduction to the Web</Part>
            {posts.filter(part(1)).map(TocItem)}

            <Part>Part II - HTML</Part>
            {posts.filter(part(2)).map(TocItem)}

            <Part>Part III - CSS</Part>
            {posts.filter(part(3)).map(TocItem)}

            <Part>Part IV - JavaScript</Part>
            {posts.filter(part(4)).map(TocItem)}

            <Part>Part V - Web Applications</Part>
            {posts.filter(part(5)).map(TocItem)}
          </ul>
        </section>
        <section style={{ fontSize: '0.75rem' }}>
          <p>
            ©{new Date().getFullYear()} Nicholas D. Freeman. All rights
            reserved.
          </p>
          <br />

          <p>
            The content in this book is provided for educational purposes. Every
            effort has been made to ensure that the content is accurate and
            helpful at publishing time. However, this is not an exhaustive
            treatment of the subjects.
          </p>

          <p>
            Best practices in this field are constantly changing, and changes in
            professional practices may become necessary. Practitioners must rely
            upon their own knowledge and experience in evaluating and using and
            information or methods described herein. No liability is assumed for
            losses or damages due to the use of information provided.
          </p>

          <p>
            Product and company names mentioned herein may be trademarks of
            their respective owners. These names are used only in an editorial
            fashion with no intention of infringement of the trademarks. Where
            these names appear, and the author was aware of the trademark at the
            time of writing, the designations have been printed with initial
            capital letters.
          </p>
        </section>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___part, frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            description
            order
            slug
            part
            hideToc
          }
          headings(depth: h1) {
            value
            depth
          }
        }
      }
    }
  }
`;
